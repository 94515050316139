import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
function BookingPanel() {
  const [rooms, setRooms] = useState([]);
  const [adults, setAdults] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const incrementRooms = () => setRooms(rooms + 1);
  const decrementRooms = () => rooms > 1 && setRooms(rooms - 1);

  const incrementAdults = () => setAdults(adults + 1);
  const decrementAdults = () => adults > 1 && setAdults(adults - 1);

  const handleBookNow = async () => {
    if (!startDate || !endDate) {
      alert("Please select both check-in and check-out dates.");
      return;
    }

    try {
      const response = await fetch(
        "https://api.goldenkudlebeachresort.com/api/availability",
        {
          method: "POST",
          headers: {
            Authorization: "ganies-code",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate,
            endDate,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setRooms(await response.json());
      // console.log("API Response:", data);
      // alert("Booking successful!");
    } catch (error) {
      console.error("Error booking room:", error);
      alert("Failed to book. Please try again later.");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#000",
          minHeight: 100,
          padding: "20px 0",
        }}
      >
        <div
          style={{
            width: "60%",
            padding: 20,
            background: "#fff",
            position: "relative",
            top: -50,
            border: "1px solid #ccc",
            borderRadius: 20,
            boxShadow: "0px 3px 13px 0px #5955556e",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {/* Check-in Input */}
          <div style={{ flex: "1 1 200px", minWidth: "100px" }}>
            <label
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              Check-in
            </label>
            <input
              type="date"
              style={{
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                width: "100%",
                boxSizing: "border-box",
                fontSize: "16px",
                fontFamily: "'Roboto', sans-serif",
                backgroundColor: "#f9f9f9",
                color: "#333",
                outline: "none",
                transition: "border-color 0.3s",
              }}
              onFocus={(e) => (e.target.style.borderColor = "#007bff")}
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          {/* Check-out Input */}
          <div style={{ flex: "1 1 200px", minWidth: "100px" }}>
            <label
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              Check-out
            </label>
            <input
              type="date"
              style={{
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                width: "100%",
                boxSizing: "border-box",
                fontSize: "16px",
                fontFamily: "'Roboto', sans-serif",
                backgroundColor: "#f9f9f9",
                color: "#333",
                outline: "none",
                transition: "border-color 0.3s",
              }}
              onFocus={(e) => (e.target.style.borderColor = "#007bff")}
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          {/* Rooms and Adults Inputs */}
          {/* ... Existing code for Rooms and Adults ... */}

          {/* Book Now Button */}
          <div
            style={{
              flex: "1 1 100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={handleBookNow}
              style={{
                padding: "12px 20px",
                backgroundColor: "darkgoldenrod",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "16px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                width: "100%",
                marginTop: "11px",
              }}
            >
              Search Now
            </button>
          </div>
        </div>
      </div>
      {startDate && endDate && rooms && rooms.length > 0 && (
        <div>
          <h2 style={{ color: "#fff", textAlign: "center" }}>
            Rooms for the Date: {startDate} - {endDate}
          </h2>
          <div
            style={{
              color: "#fff",

              display: "flex",
              justifyContent: "center",
            }}
          >
            {rooms.map((room, i) => (
              <div
                style={{
                  margin: 10,
                  border: "1px solid #fff",
                  padding: 10,
                  textAlign: "center",
                  borderRadius: 10,
                }}
              >
                <img
                  src={room.data?.images[0]}
                  alt={room.name}
                  style={{ width: 200, borderRadius: 8 }}
                />
                <h2>{room.name}</h2>

                <p>{room.images}</p>
                <p>{room.price}</p>
                <button
                  onClick={(e) => {
                    navigate(
                      "/room/" +
                        room._id +
                        `?startDate=${startDate}&endDate=${endDate}`
                    );
                  }}
                  style={{
                    background: "gold",
                    padding: 10,
                    color: "red",
                    fontWeight: 800,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  BOOK NOW
                </button>
              </div>
            ))}
          </div>
          <div style={{ textAlign: "center", margin: 10 }}>
            <button
              onClick={(e) => {
                setStartDate("");
                setEndDate("");
                setRooms([]);
              }}
              style={{ padding: 10, fontWeight: 800 }}
            >
              CLEAR
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default BookingPanel;
