import { Routes, Route, Outlet, Link } from "react-router-dom";
import StickyHeader from "./components/StickyHeader";
import Home from "./pages/Home";
import RoomsDetails from "./pages/RoomsDetails";
import RoomList from "./components/HomeComponents/RoomList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="room/:id" element={<RoomsDetails />} />
          <Route path="rooms" element={<RoomList />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div style={{ overflow: "hidden" }}>
      <StickyHeader />

      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
