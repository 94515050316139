// src/components/JourneyTimeline.js
import React from "react";
import { motion } from "framer-motion";
import "./JourneyTimeline.css";
import Divider from "../Divider";

const journeySteps = [
  {
    id: 1,
    title: "Arrive at Goa Airport",
    description:
      "Fly into Goa International Airport, the nearest airport to the resort, 150 km away.",
    icon: "✈️",
  },
  {
    id: 2,
    title: "Take a Train from Madgaon",
    description:
      "Catch a scenic train ride from Madgaon Railway Station to Gokarna Road Station.",
    icon: "🚆",
  },
  {
    id: 3,
    title: "Car Drive from Gokarna",
    description:
      "A short 15-minute drive takes you to the serene shores of GoldenKuddleBeachResort.",
    icon: "🚗",
  },
  {
    id: 4,
    title: "Boat Ride to Om Beach",
    description:
      "For the adventurers, a boat ride from Om Beach is an exciting way to start your stay.",
    icon: "🛥️",
  },
];

const JourneyTimeline = () => {
  return (
    <section className="journey-timeline" id="about" style={{ paddingTop: 30 }}>
      <h2 style={{ color: "#ffdc32" }}>Journey to GoldenKuddleBeachResort</h2>
      <div className="timeline">
        {journeySteps.map((step, index) => (
          <motion.div
            key={step.id}
            className="timeline-item"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.3 }}
          >
            <div className="timeline-icon">{step.icon}</div>
            <div className="timeline-content">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
      <Divider />
    </section>
  );
};

export default JourneyTimeline;
