// src/components/RoomList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import "./RoomList.css";
import Divider from "../Divider";

import apiClient from "../../lib/api";

const RoomList = () => {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  async function loadRooms() {
    const res = await apiClient.get("rooms");
    setRooms(res.data);
  }
  useEffect(() => {
    loadRooms();
  }, []);
  return (
    <div id="rooms" style={{ paddingTop: 80 }}>
      {JSON.stringify(rooms)}
      <Divider />
      <h1 style={{ color: "gold", textAlign: "center" }}>Our Rooms</h1>
      <div className="room-list">
        {rooms.map((room) => (
          <motion.div
            onClick={(e) => {
              navigate("/room/" + room._id + ``);
            }}
            key={room.id}
            className="room-card"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={room.data?.images[0]} alt={room.type} />
            <h2>{room.name}</h2>
            <p>{room.images}</p>
            <p>{room.price}</p>
          </motion.div>
        ))}
      </div>
      <Divider />
    </div>
  );
};

export default RoomList;
