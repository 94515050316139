import axios from "axios";

// Create an Axios instance with a base configuration
const apiClient = axios.create({
  baseURL: "https://api.goldenkudlebeachresort.com/api/", // Base API URL
  timeout: 15000, // Request timeout
  headers: {
    "Content-Type": "application/json", // Default content type
    // Add other headers like Authorization here if needed
  },
});

// Request interceptor to add auth tokens (if needed)
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Example: getting a token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors (like 401 unauthorized, 404 not found, etc.)
    if (error.response && error.response.status === 401) {
      // Handle 401 unauthorized errors here (e.g., redirect to login)
      console.log("Unauthorized, redirecting to login...");
    }
    return Promise.reject(error);
  }
);

export default apiClient;
