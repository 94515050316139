// src/components/Footer.js
import React from "react";
import "./Footer.css";
import Map from "./Map";

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <div className="footer-container">
        <div className="footer-section" style={{ textAlign: "center" }}>
          {/* <h3>Golden Kuddle Beach Resort</h3> */}
          <img src={require("./logoFull.png")} height={300} />
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#rooms">Rooms</a>
            </li>
            <li>
              <a href="#things-to-do">Things to Do</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p style={{ color: "gold", fontWeight: 700, fontSize: 18 }}>
            123 Beach Road, Gokarna, India
          </p>
          <p>
            Email:{" "}
            <span style={{ color: "gold", fontWeight: 500, fontSize: 22 }}>
              connect@goldenkuddlebeachresort.com
            </span>
          </p>
          <p>
            Phone:{" "}
            <span style={{ color: "gold", fontWeight: 400, fontSize: 22 }}>
              +91 96864 70433
            </span>
          </p>
        </div>
        <div className="footer-section map-section">
          <h3>Location</h3>
          <Map />
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 GoldenKuddleBeachResort. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
