function HeroBanner() {
  return (
    <div style={{ padding: 20 }}>
      <img
        style={{ width: "100%", maxHeight: 500, borderRadius: 10 }}
        src={
          "https://api.goldenkudlebeachresort.com/uploads/1732937776573-213997138.jpg"
        }
        alt="hero banner"
      />
    </div>
  );
}

export default HeroBanner;
