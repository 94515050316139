const Divider = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 60,
        marginBottom: 60,
      }}
    >
      <hr style={{ flexGrow: 1, border: "0", borderTop: "1px solid gold" }} />
      <div
        style={{
          width: "10px",
          height: "10px",
          background: "gold",
          borderRadius: "50%",
          margin: "0 10px",
        }}
      ></div>
      <hr style={{ flexGrow: 1, border: "0", borderTop: "1px solid gold" }} />
    </div>
  );
};

export default Divider;
