import React from 'react';

function OfferCard({ image, title, date, datetitile, text }) {
  return (
    <div style={styles.card}>
      <img src={image} alt={title} style={styles.image} />
      <div style={styles.content}>
        <div style={styles.title}>{title}</div>
        <div style={styles.date}>{date}</div>
        <div style={styles.datetitile}>{datetitile}</div>
        <div style={styles.text}>{text}</div>
        <button style={styles.button}>View Details</button>
      </div>
    </div>
  );
}

const styles = {
  card: {
    width: '350px',
    backgroundColor: '#fff',
    textAlign: 'center',
    height: 'auto',
    border: '1px solid rgb(0 0 0 / 6%)',
    overflow: 'hidden',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  content: {
    padding: '15px',
  },
  title: {
    margin: '10px 0',
    fontSize: '16px',
    backgroundColor: '#dee4e4',
    borderRadius: '5px',
    display: 'inline-flex',
  },
  date: {
    margin: '10px 0',
    fontSize: '14px',
    color: '#888',
  },
  datetitile: {
    margin: '10px 0',
    fontSize: '16px',
    color: '#555',
    fontWeight: '600',
  },
  text: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '15px',
  },
  buttonContainer: {
    position: 'absolute', // Position the button container at the bottom
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)', // Center the button horizontally
  },
  button: {
    padding: '10px 20px',
    backgroundColor: 'darkgoldenrod',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default OfferCard;
